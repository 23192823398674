<template>
  <div class="support-footer" v-if="isShow">
    <ul>
      <li v-for="(item, index) in supportList" :key="index" :class="otherClass">
        {{ item.text }}
      </li>
    </ul>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    otherClass: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const defaultList = [
      {
        icon: "",
        text: "广州致远科教软件有限公司提供技术支持",
      },
    ];

    const state = reactive({
      isShow: true,
      supportList: [...props.list, ...defaultList],
    });

    if (state.supportList.length < 1) {
      state.isShow = false;
    }

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
@import "../common/style/base.less";

@media screen and (orientation: portrait) and (min-device-width: 420px) {
  .width40 {
    width: calc(40vw - 20px - 20px);
  }
}

/* 竖屏 */
@media screen and (orientation: portrait) {
  .support-footer {
    position: fixed;
    left: -24px;
    z-index: 0;
    bottom: 40px;
    width: 100vw;
    height: auto;

    ul {
      width: 100%;

      li {
        width: 102%;
        height: 20px;
        margin-bottom: 20px;
        text-align: center;
        color: #a1a1a1;
        padding: 10px 20px;
        font-size: 10px;
      }

      .textLeft {
        text-align: left;
      }
    }
  }
}

@media screen and (orientation: portrait) and(min-device-height: 690px) {
  .otherClass {
    display: none;
  }
}

/* 横屏 */
@media screen and (orientation: landscape) {
  .support-footer {
    position: fixed;
    z-index: 0;
    left: 0;
    bottom: 0;
    width: calc(100vw - 50px);
    height: 20px;
    margin-left: 50px;
    background-color: #fff;
    border-top: 1px solid #ebedf0;
    z-index: 999;
    overflow: hidden;

    ul {
      width: 100%;

      li {
        text-align: center;
        height: 20px;
        line-height: 22px;
        color: #a1a1a1;
      }
    }
  }
}
</style>
