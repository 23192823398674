import request from "@/utils/request";
import requestForm from "@/utils/requestForm";

/** 未读数
 * 
 */
export function apiGetStudentCount(params) {
  return request.get("/h5/student.notice/countNoRead", { params });
}

/**
 * 列表
 */
export function apiGetStudentLists(params) {
  return request.get("/h5/student.notice/lists", { params });
}

/** 已读
 * id
 */
export function apiStudentToRead(params) {
  return requestForm.post("/h5/student.notice/toRead", params);
}

/**
 * 列表
 */
export function apiGetTeacherLists(params) {
  return request.get("/h5/teacher.notice/lists", { params });
}

/**
 * 详情
 */
export function apiGetTeacherInfo(params) {
  return request.get("/h5/teacher.notice/getInfo", { params });
}

/** 保存
 * id
 */
export function apiTeacherToSave(params) {
  return requestForm.post("/h5/teacher.notice/toSave", params);
}

/** 发布
 * id
 */
export function apiTeacherToPost(params) {
  return requestForm.post("/h5/teacher.notice/toPost", params);
}

/** 删除
 * id
 */
export function apiTeacherToDel(params) {
  return requestForm.post("/h5/teacher.notice/toDel", params);
}

