<template>
  <div class="nav-bar nav-bar-base">
    <ul class="nav-list">
      <li
        class="nav-list-item"
        v-for="(item, index) in activeList"
        :key="index"
        :class="item.activeType == activeItemName ? 'active' : ''"
        @click="toRoute(item)"
      >
        <div class="activeItem">
          <div>
            <div class="iconBox">
              <van-icon :name="item.iconName" size="22" :dot="item.dot"/>
            </div>
            <div class="activeName">{{ item.text }}</div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { reactive, computed, toRefs } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "vant";

import { apiGetStudentCount } from "@/service/notice";

export default {
  props: {
    userType: {
      type: String,
    },
    activeItemName: {
      type: String,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const router = useRouter();

    const defaultActiveList = [
      //student
      {
        iconName: "wap-home-o",
        text: "学习台",
        userType: "student",
        activeType: "index",
        to: "studentIndex",
        dot: false
      },
      {
        iconName: "chart-trending-o",
        text: "学情分析",
        userType: "student",
        activeType: "analysis",
        to: "studyAnalysis",
        dot: false
      },
      {
        iconName: "chat-o",
        text: "消息通知",
        userType: "student",
        activeType: "news",
        to: "studentNews",
          dot: false
      },
      {
        iconName: "contact",
        text: "个人中心",
        userType: "student",
        activeType: "info",
        to: "studentInfo",
        dot: false
      },
      //teacher
      {
        iconName: "wap-home-o",
        text: "工作台",
        userType: "teacher",
        activeType: "index",
        to: "teacherIndex",
        dot: false
      },
      {
        iconName: "chart-trending-o",
        text: "教学分析",
        userType: "teacher",
        activeType: "AnaTeaching",
        to: "AnaTeaching",
        dot: false
      },
      /* {
        iconName: "contact",
        text: "打印中心",
        userType: "teacher",
        activeType: "printCenter",
        to: "printCenter",
        dot: false
      }, */
      {
        iconName: "friends-o",
        text: "班级管理",
        userType: "teacher",
        activeType: "classManagement",
        to: "classManagement",
        dot: false
      },
      {
        iconName: "comment-o",
        text: "通知管理",
        userType: "teacher",
        activeType: "noticeManagement",
        to: "noticeManagement",
        dot: false
      },
      {
        iconName: "contact",
        text: "个人中心",
        userType: "teacher",
        activeType: "info",
        to: "teacherInfo",
        dot: false
      },
      //principal
      {
        iconName: "wap-home-o",
        text: "首页",
        userType: "principal",
        activeType: "index",
        to: "principalIndex",
        dot: false
      },
    ];

    const activeMergeList = [...defaultActiveList, ...props.list];

    const state = reactive({
      /* activeList: computed(() => {
        return activeMergeList.filter((item) => {
			
          if(item.activeType == 'news'){
            item.dot = true
            
            // await apiGetStudentCount().then((res) => {
            //   if(res.data > 0){
            //     item.dot = true
            //   }
            // })
          }
			
          return item.userType == props.userType || item.userType == "";
        });
      }), */
      activeList: []
    });
      
    activeMergeList.forEach(async (item, i) => {
      if(item.userType == props.userType || item.userType == ""){
        state.activeList.push(item)
        let index = state.activeList.length - 1
        
        if(item.activeType == 'news'){
          await apiGetStudentCount().then((res) => {
            if(res.data > 0){
              state.activeList[index].dot = true
            }
          })
        }
      }
    })

    const toRoute = (activeItem) => {
      if (activeItem.to) {
        router.push({ name: activeItem.to });
      } else {
        Toast.fail("功能未开放！");
      }
    };

    return {
      ...toRefs(state),
      toRoute,
    };
  },
};
</script>

<style lang="less" scoped>
@import "../common/style/base.less";
@import "../common/style/mixin.less";

/* 竖屏 */
@media screen and (orientation: portrait) {
  .nav-bar {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999999;
    width: 100vw;
    height: 50px;
    padding-top: 10px;
    border-top: 1px solid #ebedf0;
    overflow-x: auto;
    overflow-y: hidden;

    .nav-list {
      // padding: 0 10px;
      width: auto;
      min-width: calc(100% - 20px);
      text-align: center;

      .nav-list-item {
        display: inline-block;
        margin: 0;
        padding: 0 5px;
        width: 50px;
        height: 40px;
        text-align: center;

        .activeItem {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .activeName {
            font-size: 10px;
          }
        }
      }
    }
  }
}

/* 横屏 */
@media screen and (orientation: landscape) {
  .nav-bar {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 49px;
    height: calc(100vh - 30px);
    margin-top: 30px;
    padding-top: 10px;
    border-right: 1px solid #ebedf0;
    overflow-x: hidden;
    overflow-y: auto;

    .nav-list {
      width: 49px;
      height: auto;
      padding-bottom: 10px;

      .nav-list-item {
        margin: 0 auto 5px;
        padding: 5px 0;
        width: 39px;
        text-align: center;

        .activeItem {
          width: 100%;
          .activeName {
            font-size: 8px;
          }
        }
      }
    }
  }
}

.nav-bar-base {
  background-color: #fff;
}
:deep(.van-badge__wrapper) {
  padding: 5px;
  border-radius: 50%;
  background-color: #eee;
}

.activeName {
  margin-top: 5px;
}

.active {
  color: #fff;
  color: #18a4e0;
  font-weight: 700;
  :deep(.van-badge__wrapper) {
    padding: 5px;
    border-radius: 50%;
  }
}
</style>
